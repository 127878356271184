<template>
    <span class="tooltipTop">
        {{ details }}
    </span>
</template>

<script>

export default {
    name: 'Component_Tooltips_Keyresult',
    props: {
        details: Text,
    },
}

</script>
