<template>
    <div class="notification is-info mt-4 text-center" v-if="!isPlanningActive">
        Achtung: Sie machen gerade eine Eintragung <br>
        außerhalb der dafür vorgesehenen Planningzeit.
    </div>
    <strong class="is-flex is-justify-content-center is-align-items-center p-3">
        Neues Keyresult hinzufügen
    </strong>
    <table>
        <tbody>
            <!-- ################### Name ###################### -->
            <tr>
                <td class="vertical-center">Name:</td>
                <td class="vertical-center">
                    <div>
                        <textarea type="text" class="input-field-textarea" placeholder=" " v-model="name" />
                    </div>
                </td>
            </tr>
            <!-- ################### Details ###################### -->
            <tr>
                <td class="vertical-center">Details:</td>
                <td class="vertical-center">
                    <div>
                        <textarea type="text" class="input-field-textarea" placeholder=" " v-model="details" />
                    </div>
                </td>
            </tr>
            <!-- ################### Owner ###################### -->
            <tr>
                <td class="vertical-center">Owner:</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <select class="input-field-big" v-model="owner">
                            <option v-for="user in team_users" :value="user.id">{{ user.first_name }} {{ user.last_name }}   </option>
                        </select>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Startwert:</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <input type="number" class="input-field-medium" placeholder=" " v-model.number="start" @input="validatePercentage">
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Zielwert:</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <input type="number" class="input-field-medium" placeholder=" " v-model.number="goal" @input="validatePercentage">
                    </div>
                </td>
            </tr>
            <!-- <tr>
                <td class="vertical-center">Aktueller Wert:</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <input type="number" class="input-field-medium" placeholder=" " v-model.number="value_current">
                    </div>
                </td>
            </tr> -->
            <tr>
                <td class="vertical-center">Einheit:</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <select class="input-field-medium" v-model="unit" @change="handleCustomUnit">
                            <option value="%" selected>%</option>
                            <option value="€">€</option>
                            <option value="custom">Selbstdefiniert</option>
                        </select>
                        <input v-if="customSelected" type="text" class="input-field-small" v-model="customUnit">
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Status:</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <select class="input-field-big" v-model="status">
                            <!-- <option value="" disabled selected>Status</option> -->
                            <option value="Not started" selected>Not started</option>
                            <option value="Off track">Off track</option>
                            <option value="Progressing">Progressing</option>
                            <option value="On track">On track</option>
                        </select>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Gewicht:</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <input type="number" class="input-field-medium" v-model.number="weight_multiplier">
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center"></td>
                <td class="vertical-center">

                </td>
            </tr>
            <tr>
                <td>
                    &nbsp;
                </td>
            </tr>
        </tbody>
    </table>
    <div class="is-flex is-justify-content-center is-align-items-center p-3">
        <button class="button is-dark" @click="submitForm">Speichern</button>
    </div>
    <div class="notification is-danger mt-4" v-if="errors.length">
        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Modal_AddKeyresult',
    emits: ['keyResultAdded'],
    props: {
        objective: Object,
        team_users: Array,
        isPlanningActive: Boolean,
    },
    data() {
        return {
            name: 'Default Keyresult',
            details: '',
            start: 0,
            goal: 100,
            value_current: 0,
            unit: '%',
            weight_multiplier: 1,
            status: 'Not started',
            errors: [],
            customUnit: '',
            owner: [],
            team: null,
            customSelected: false
        }
    },
    watch: {
        '$store.state.currentUser': {
            immediate: true,
            handler(newValue, oldValue) {
                // console.log('Current user changed:', newValue);
            }
        }
    },
    mounted() {
        console.log('Team_users:', this.team_users)
        if (this.team_users.length > 0)
            this.owner = this.team_users[0].id
    },
    methods: {
        submitForm() {
            this.errors = []

            if (this.name === '') {
                this.errors.push('The name field is missing!')
            }
            if (this.owner === '') {
                this.errors.push('The owner field is missing!')
            }
            if (this.start === '') {
                this.errors.push('The start value is missing!')
            }
            if (this.goal === '') {
                this.errors.push('The goal value is missing!')
            }
            // if (this.value_current === '' || isNaN(+this.value_current)) {
            //     this.value_current = this.start
            // }
            if (this.status === '') {
                this.errors.push('The status field is missing!')
            }
            if (this.weight_multiplier === '') {
                this.weight_multiplier = 1
            }
            if (!this.errors.length) {
                // this.progress = parseInt(this.progress);
                // if (this.unit === 'custom') {
                //     this.unit = this.customUnit
                // };
                // console.log('Custom unit:', this.customUnit)
                this.addKeyResult();
                // this.unit = '%';
                // this.user = localStorage.getItem('user')
            }
        },
        async addKeyResult() {
            this.$store.commit('setIsLoading', true)
            console.log('Adding keyresult:', this.name, this.objective.id, this.start, this.goal, this.value_current, this.unit, this.customUnit, this.status, this.weight_multiplier)
            await axios
                .post('/api/v1/keyresults/', 
                {
                objective: this.objective.id,
                name: this.name,
                details: this.details,
                team: this.objective.team, 
                owner: this.owner,
                // creator: this.objective.creator,
                unit: this.unit,
                custom_unit: this.customUnit,
                start: this.start,
                goal: this.goal,
                value_current: this.start,
                status: this.status,
                weight_multiplier: this.weight_multiplier,
                },
                )
                .then(response => {
                this.$emit('keyResultAdded');
                })
                .catch(error => {
                console.error('Error:', error)
                })
            this.$store.commit('setIsLoading', false)
        },
        handleCustomUnit() {
            this.validatePercentage()
            if (this.unit === 'custom') {
                this.customSelected = true;
            } else {
                this.customSelected = false;
            }
        },
        validatePercentage() {
            // TODO: Don't delete, needs to be adjusted
            if (this.unit === '%') {
                if (this.start < 0) {
                    this.start = 0;
                } else if (this.goal > 100) {
                    this.goal = 100;
                }
            }
        }
    }
}
</script>

<style scoped>


</style>