<template>
    <strong class="is-flex is-justify-content-center is-align-items-center p-3">
        Vision ändern
    </strong>
    <table>
        <tbody>
            <tr>
                <td class="vertical-center">Name:</td>
                <td class="vertical-center">
                    <div>
                        <textarea type="text" class="input-field-textarea" v-model=editedVision.name />
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="is-flex is-justify-content-center is-align-items-center p-3">
        <button class="button is-dark" @click="submitForm">Speichern</button>
    </div>
    <div class="notification is-danger mt-4" v-if="errors.length">
        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
    </div>
    <div class="is-flex is-justify-content-center is-align-items-center p-3">
        <button class="button is-danger" @click="deleteVision">Löschen</button>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Modal_ChangeVision',
    emits: ['visionChanged'],
    props: {
        vision: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            errors: [],
            showHelpText: false,
            editedVision: { ...this.vision },
        }
    },
    methods: {
        submitForm() {
            this.errors = []
            if (this.editedVision.name === '') {
                this.errors.push('The name field is missing!')
            }
            if (!this.errors.length) {
                this.changeVision();
            }
        },
        async changeVision() {
            this.$store.commit('setIsLoading', true)
            await axios
                .put(`/api/v1/visions/${this.vision.id}/`, 
                {
                name: this.editedVision.name,
                },
                )
                .then(response => {
                this.$emit('visionChanged');
                })
                .catch(error => {
                    if (error.response) {

                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    } else if (error.request) {
                    console.log(error.request);
                    } else {
                    console.log('Error', error.message);
                    }
                    console.log(error.config);
                })
            this.$store.commit('setIsLoading', false)
        },
        async deleteVision() {
            this.$store.commit('setIsLoading', true)
            await axios
                .delete(`/api/v1/visions/${this.vision.id}/`)
                .then(response => {
                this.$emit('visionChanged');
                })
                .catch(error => {
                    if (error.response) {

                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    } else if (error.request) {
                    console.log(error.request);
                    } else {
                    console.log('Error', error.message);
                    }
                    console.log(error.config);
                })
            this.$store.commit('setIsLoading', false)
        }
    }
}
</script>
