<template >
  <div class="max-w-[600px] text-white">
    <!-- <line-chart :chart-data="chartData" :options="chartOptions"></line-chart> -->
    <canvas ref="myChart" class="w-[600px] h-[600px] "></canvas>
    <div v-if="isCheckinSelected" class="flex-col w-[50%]">
        <!-- <button @click="isCheckinSelected = false" class="close-btn">x</button> -->
        <Component_OKR_Checkin_Detail
          :checkin="selectedCheckin" 
          @close="isCheckinSelected = false"
          @checkinDeleted="updateChart"
        />
    </div>
    <div class="mt-3">
      <strong>
        Keyresult Details:
      </strong> 
      <span>{{ keyresult.details }}</span>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import { defineComponent, ref, onMounted } from 'vue';
import { Chart, registerables } from 'chart.js';
import { nextTick } from 'vue';
import 'chartjs-adapter-date-fns'; // Import the date adapter
import Component_OKR_Checkin_Detail from '@/components/OKR/Component_OKR_Checkin_Detail.vue';



Chart.register(...registerables);

export default {
  name: 'Modal_OKR_Keyresult_Detail',
  components: {
    Component_OKR_Checkin_Detail,
  },
  props: {
    keyresult: Object,
    threshold_green: Number,
    threshold_red: Number,
  },
  data() {
    return {
      checkins: [],        
      myChart: null,
      checkinData: [],
      goalLine: [],
      dangerLine: [],
      selectedCheckin: null,      // Selected checkin data
      isCheckinSelected: false,   // Flag to indicate a checkin is selected

    };
  },
  mounted() {
    console.log("Detail Keyresult", this.keyresult);
    this.getCheckins();
    console.log("threshold_green", this.threshold_green);
    console.log("threshold_red", this.threshold_red);
    // nextTick(() => {
    //   this.renderChart();
    // });
    // this.renderChart(this.chartData, this.options);

  },
  methods: {
    async updateCheckinData() {
      this.checkinData = this.generateCheckinData();
      this.goalLine = this.generateGoalData();
      this.dangerLine = this.generateRedYellowData();
      nextTick(() => {
        this.renderChart();
      });
    },
    async getCheckins() {
      this.$store.commit('setIsLoading', true);
      try {
        const response = await axios.get(`/api/v1/checkins/?keyresult_id=${this.keyresult.id}`);
        this.checkins = response.data;
        // console.log('Checkins:', this.checkins);
        this.checkinData = this.generateCheckinData(); // Populate chartData after checkins is fetched
        this.goalLine = this.generateGoalData();
        this.dangerLine = this.generateRedYellowData();
        this.yellowLine = this.generateYellowGreenData();
        nextTick(() => {
          this.renderChart();
        });
      } catch (error) {
        console.error('Error fetching checkins:', error);
      } finally {
        this.$store.commit('setIsLoading', false);
      }
    },
    generateCheckinData() {
      const sortedCheckins = this.checkins.sort((a, b) => new Date(a.date) - new Date(b.date));

      // return this.checkins.map(checkin => ({
      return sortedCheckins.map(checkin => ({
        x: new Date(checkin.date),
        y: checkin.value,
        id: checkin.id // Ensure each data point has an 'id' field
      }));
    },
    generateGoalData() {
      return [
        { x: new Date(this.keyresult.working_period_start), y: this.keyresult.start },
        { x: new Date(this.keyresult.working_period_end), y: this.keyresult.goal }
      ];
    },
    generateRedYellowData() {
      const isAscending = this.keyresult.start < this.keyresult.goal;
      const thresholdValue = this.keyresult.goal * this.threshold_red * 0.01;
      return [
        { x: new Date(this.keyresult.working_period_start), y: this.keyresult.start },
        { 
          x: new Date(this.keyresult.working_period_end), 
          y: isAscending ? thresholdValue : ((this.keyresult.start - (this.keyresult.start - this.keyresult.goal)*this.threshold_red*0.01))
          // y:  }
        }
      ];
    },
    generateBackgroundData() {
      return [
        { x: new Date(this.keyresult.working_period_start), y: this.keyresult.start },
        { x: new Date(this.keyresult.working_period_end), y: this.keyresult.start }
      ];
    },
    generateYellowGreenData() {
      const isAscending = this.keyresult.start < this.keyresult.goal;
      const thresholdValue = this.keyresult.goal * this.threshold_green * 0.01;
      return [
        { x: new Date(this.keyresult.working_period_start), y: this.keyresult.start },
        { 
          x: new Date(this.keyresult.working_period_end), 
          y: isAscending ? thresholdValue : ((this.keyresult.start - (this.keyresult.start - this.keyresult.goal)*this.threshold_green*0.01))
        }
        // { x: new Date(this.keyresult.working_period_end), y: this.keyresult.goal*this.threshold_green*0.01 }
      ];
    },
    renderChart() {
      const ctx = this.$refs.myChart.getContext('2d');
      if (this.myChart) {
        this.myChart.destroy();
      }
      this.myChart = new Chart(ctx, {
        type: 'line',
        data: {
          datasets: [{
            label: 'Check-in',
            data: this.checkinData,
            fill: false,
            borderColor: 'rgba(0, 175, 240, 1)',
            tension: 0.1,
            // z: 7,
          },
          {
            label: 'Ziel',
            data: this.goalLine,
            fill: false,
            borderColor: 'rgba(0, 0, 0, 1)',
            tension: 0.1,
            pointRadius: 0,
            pointHoverRadius: 0,
            // pointHoverBackgroundColor: 'rgba(0, 0, 0, 0)',

            // z: -10,
          },
          {
            label: 'Off track',
            data: this.dangerLine,
            // fill: true,
            fill: this.keyresult.start < this.keyresult.goal ? true : 'end',
            borderColor: 'rgba(0, 0, 0, 0)',
            // backgroundColor: 'rgba(255, 55, 155, 1)', // Use backgroundColor for fill color
            backgroundColor: 'rgba(255, 100, 100, 1)', // Use backgroundColor for fill color
            tension: 0.1,
            pointRadius: 0,
            pointHoverRadius: 0,
            // pointHoverBackgroundColor: 'rgba(0, 0, 0, 0)',
            // z: 200,
          },
          {
            label: 'Progressing',
            data: this.yellowLine,
            // fill: true,
            fill: this.keyresult.start < this.keyresult.goal ? true : '-1',
            borderColor: 'rgba(0, 0, 0, 0)',
            backgroundColor: 'rgba(255, 235, 50, 1)', // Use backgroundColor for fill color
            tension: 0.1,
            pointRadius: 0,
            pointHoverRadius: 0,
            // pointHoverBackgroundColor: 'rgba(0, 0, 0, 0)',
            // z: -10,
          },
          {
            label: 'On Track',
            data: this.generateBackgroundData(),
            // fill: 'end',
            fill: this.keyresult.start < this.keyresult.goal ? 'end' : 'start',
            backgroundColor: 'rgba(0, 240, 0, 0.5)',
            borderColor: 'transparent',
            tension: 0,
            pointRadius: 0,
            order: 5,
            // fill: 'origin'
        }
        ]
        },
        options: {
          plugins: {
            title: {
              display: true,
              text: this.keyresult.name,
              font: {
                  size: 18, // adjust size as needed
              }
            },
            legend: {
              onClick: function(e, legendItem, legend) {
                // Disables the ability to toggle the visibility of a dataset
                return
              }
            }
          },
          scales: {
            x: {
              type: 'time',
              time: {
                unit: 'day'
              },
              grid: {
                z: 10, // Ensure grid lines are in the foreground
              },
            },
            y: {
              // beginAtZero: true,
              grid: {
                z: 10, // Ensure grid lines are in the foreground
              },
              ticks: {
                callback: (value, index, ticks) => {
                  if (index === ticks.length - 1) {
                    return this.keyresult?.unit === 'custom'
                      ? this.keyresult?.custom_unit+" "+value
                      : this.keyresult?.unit+" "+value;
                  }
                  return value;
                }
              }
            }
          },
          onClick: (event, elements) => {
            this.handleChartClick(event, elements);
          }
        }
      });
    },
    handleChartClick(event, elements) {
      if (elements.length > 0) {
        const clickedElement = elements[0];
        const datasetIndex = clickedElement.datasetIndex;
        const dataIndex = clickedElement.index;
        const dataset = this.myChart.data.datasets[datasetIndex];
        const dataPoint = dataset.data[dataIndex];

        // Ensure the dataPoint has an 'id' field
        if (dataPoint && dataPoint.id) {
          const checkinId = dataPoint.id;
          const checkin = this.checkins.find(c => c.id === checkinId);

          if (checkin) {
            this.selectedCheckin = checkin;
            this.isCheckinSelected = true;
          }
        }
      }
    },
    updateChart() {
      this.isCheckinSelected = false;
      this.getCheckins();
    },

    
  //   updateChartData() {
  //     const labels = this.checkins.map(checkin => new Date(checkin.date_added));
  //     const data = this.checkins.map(checkin => checkin.value);

  //     this.chartData.labels = labels;
  //     this.chartData.datasets[0].data = data;
  //   },
  },
};
</script>

<style scoped>
.chart-container {
  position: relative;
  height: 400px;
}
</style>