<template>
  <button  @click="$emit('toggle')" class="z-50 h-10 w-4
           bg-gray-300 dark:bg-gray-600 hover:bg-vantapink-400 dark:hover:bg-vantapink-500 rounded-r-lg p-1 
           transition-all duration-300 ease-in-out"
    >
    <span v-if="isOpen" class="w-10 h-20 transition-all duration-1000 ease-in-out text-black" >
      ❮
    </span>
    <span v-else class="w-10 h-20 transition-all duration-1000 ease-in-out text-black">
      ❯
    </span>
  </button>
</template>

<script>
export default {
emits: ['toggle'],
  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  }
}
</script>