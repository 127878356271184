<template>
    <strong class="is-flex is-justify-content-center is-align-items-center p-3">
        Planning bearbeiten
    </strong>
    <table>
        <tbody>
            <!-- <tr>Planning Zeitraum</tr> -->
            <tr>
                <td class="vertical-center">Beginn:</td>
                <td class="vertical-center">
                    <div>
                        <input type="datetime-local" class="input-field-big-2" v-model="this.editedPlanning.start">
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Ende:</td>
                <td class="vertical-center">
                    <div>
                        <input type="datetime-local" class="input-field-big-2" v-model="this.editedPlanning.end">
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Arbeitszeitraum:</td>
                <td class="vertical-center">
                    <div class="checkbox-group">
                        <div v-for="period in sortedWorkingPeriods" :key="period.id" class="checkbox-option">
                            <input
                                type="checkbox"
                                :id="`quarter-${period.value}`"
                                v-model="period.is_active"
                            >
                            <!-- :checked="isPeriodSelected(period)" -->
                            <!-- :value="period" -->
                            <!-- v-model="this.selectedPeriods" -->

                            <label :for="`quarter-${period.value}`">&nbsp; {{ period.name }}</label>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>

    <div class="is-flex is-justify-content-center is-align-items-center p-3">
        <button class="button is-dark" @click="submitForm">Änderungen speichern</button>
    </div>
    <div class="is-flex is-justify-content-center is-align-items-center p-3">
        <button class="button is-danger" @click="inactivatePlanning">Planning beenden</button>
    </div>


    <div class="notification is-danger mt-4" v-if="errors.length">
        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
    </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'

export default {
    name: 'Modal_ChangePlanning',
    emits: ['planningChanged', 'workingPeriodsChanged'],
    props: {
        planning: {
            type: Object,
            required: true
        },
        objectives_inactive: Object
    },
    data() {
        return {
            editedPlanning: { ...this.planning },
            // workingPeriods: { ...this.planning.working_periods },
            errors: [],
            showHelpText: false,
        }
    },
    computed: {
        selectedPeriods() {
            return this.planning.working_periods.filter(period => period.is_active);
        },
        sortedWorkingPeriods() {
            // Ensure working_periods is an array and sort by id
            return Array.isArray(this.editedPlanning.working_periods)
                ? this.editedPlanning.working_periods.slice().sort((a, b) => a.id - b.id)
                : [];
        }
    },
    mounted() {
        // this.generateWorkingPeriods()
        this.formatDate();
        // this.preselectWorkingPeriods();
        console.log("this.editingPlanning", this.editedPlanning)
    },
    methods: {
        formatDate() {
            if (this.editedPlanning.start) {
                const date = new Date(this.editedPlanning.start);
                const year = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const day = date.getDate().toString().padStart(2, '0');
                const hours = date.getHours().toString().padStart(2, '0');
                const minutes = date.getMinutes().toString().padStart(2, '0');
                // Adjust the date format to YYYY-MM-DDTHH:mm
                this.editedPlanning.start = `${year}-${month}-${day}T${hours}:${minutes}`;
            }
            if (this.editedPlanning.end) {
                const date = new Date(this.editedPlanning.end);
                const year = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const day = date.getDate().toString().padStart(2, '0');
                const hours = date.getHours().toString().padStart(2, '0');
                const minutes = date.getMinutes().toString().padStart(2, '0');
                // Adjust the date format to YYYY-MM-DDTHH:mm
                this.editedPlanning.end = `${year}-${month}-${day}T${hours}:${minutes}`;
            }
        },
        // preselectWorkingPeriods() {
        //     // console.log('this.editedPlanning.working_periods:', this.editedPlanning.working_periods);
        //     this.selectedPeriods = this.editedPlanning.working_periods.filter(period => period.is_active);
        //     // console.log('this.selectedPeriods new:', this.selectedPeriods);
        // },
        // isPeriodSelected(period) {
        //     const isSelected = this.selectedPeriods.some(selected => selected.id === period.id);
        //     // console.log(`Period ${period.id} selected:`, isSelected);
        //     return isSelected;
        // },
        submitForm() {
            this.errors = []

            if (this.editedPlanning.start === '') {
                this.errors.push('The start field is missing!')
            }
            
            if (this.editedPlanning.end === '') {
                this.errors.push('The end field is missing!')
            }
            if (this.selectedPeriods.length === 0) {
                this.errors.push('The working period is missing!')
            }
            if (!this.errors.length) {
                // console.log('this.editedPlanning.working_periods:', this.editedPlanning.working_periods);
                // console.log('this.workingPeriods:', this.workingPeriods);
                // console.log('this.selectedPeriods:', this.selectedPeriods);
                this.changePlanning();
                this.changeWorkingPeriods();
            }

        },
        async changePlanning() {
            // Construct the payload for planning
            const planningPayload = {
                start: this.editedPlanning.start,
                end: this.editedPlanning.end,
                // working_periods: [],
            };
            this.$store.commit('setIsLoading', true);
            await axios
                .patch(`/api/v1/plannings/${this.editedPlanning.id}/`, planningPayload)
                .then(response => {
                    // console.log('response:', response);
                    this.$emit('planningChanged', response.data);
                    this.$emit('workingPeriodsChanged');
                })
                .catch(error => {
                    console.error('An error occurred:', error);
                });
            this.$store.commit('setIsLoading', false);

            // // Add working periods data to the payload
            // for (const period of this.selectedPeriods) {
            //     console.log('period:', period);
            //     planningPayload.workingPeriods.push({
            //         start: period.startDate,
            //         end: period.endDate,                   
            //         // Include any other necessary data for each working period
            //     });
            // }
        },
        async changeWorkingPeriods() {
            this.$store.commit('setIsLoading', true);
            for (const period of this.editedPlanning.working_periods) {
                // const isActive = this.isPeriodSelected(period);
                const payload = {
                    is_active: period.is_active,
                // start: period.start,
                // end: period.end,
                // is_active: is_active,
                };
                // console.log('payload:', payload, 'for period:', period);
                try {
                    await axios.patch(`/api/v1/working-periods/${period.id}/`, payload);
                    this.$emit('workingPeriodsChanged');
                    console.log('Working period updated:', period);
                } catch (error) {
                    console.error('An error occurred:', error);
                }
            }
            this.$store.commit('setIsLoading', false);
            this.$emit('workingPeriodsChanged');
        },
        // isPeriodSelected(period) {
        //     return this.selectedPeriods.some(selected => selected.id === period.id);
        // },  
        inactivatePlanning() {
            axios.patch(`/api/v1/plannings/${this.editedPlanning.id}/`, 
            {
                is_active: false
            })
            .then(response => {
                // console.log('Planning deleted:', response.data);
                this.$emit('planningChanged', null);
                this.deleteObjectives();

            })
            .catch(error => {
                console.error('An error occurred:', error);
            });
        },
        deleteObjectives() {
            console.log('this.objectives_inactive:', this.objectives_inactive)
            if (!this.objectives_inactive) {
                console.log('No objectives to delete');
                return;
            }
            else {
                // console.log('Objectives to delete:', this.objectives_inactive);
                for (const objective of this.objectives_inactive) {
                axios.delete(`/api/v1/objectives/${objective.id}/`)
                    .then(response => {
                        console.log('Objectives deleted:', response.data);
                    })
                    .catch(error => {
                        console.error('An error occurred:', error);
                    });
                }
            }
        },
    }
}
</script>

<style scoped>


</style>