<template>
    <strong class="is-flex is-justify-content-center is-align-items-center p-3">
        Neues Planning
    </strong>
    <table>
        <tbody>
            <!-- <tr>Planning Zeitraum</tr> -->
            <tr>
                <td class="vertical-center">Beginn:</td>
                <td class="vertical-center">
                    <div>
                        <input type="datetime-local" class="input-field-big-2" v-model="start_planning">
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Ende:</td>
                <td class="vertical-center">
                    <div>
                        <input type="datetime-local" class="input-field-big-2" v-model="end_planning">
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Arbeitszeitraum:</td>
                <td class="vertical-center">
                    <div class="checkbox-group">
                        <div v-for="period in workingPeriods" :key="period.label" class="checkbox-option">
                            <input
                                type="checkbox"
                                :id="period.label"
                                :value="period"
                                v-model="selectedPeriods"
                            >
                            <label class="px-2" :for="period.label">{{ period.label }}</label>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>

    <div class="is-flex is-justify-content-center is-align-items-center p-3">
        <button class="button is-dark" @click="submitForm">Planning starten</button>
    </div>
    <div class="notification is-danger mt-4" v-if="errors.length">
        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
    </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import { generateWorkingPeriods, getToday, getInThreeWeeks } from '@/utils/utils'

export default {
    name: 'Modal_AddObjective',
    emits: ['planningAdded', 'workingPeriodsAdded'],
    data() {
        return {
            start_planning: getToday(),
            end_planning: getInThreeWeeks(),
            workingPeriods: generateWorkingPeriods(),
            selectedPeriods: [], 
            // workingPeriods: '',
            errors: [],
            showHelpText: false,
        }
    },
    mounted() {
        this.selectedPeriods = this.workingPeriods;
        // console.log('Mounted workingPeriods:', this.workingPeriods);
    },
    methods: {
        isPeriodSelected(period) {
            return this.selectedPeriods.includes(period);
            // const isSelected = this.selectedPeriods.some(selected => selected.value === period.value);
            // console.log(`Period ${period.value} selected:`, isSelected);
            // return isSelected;
        },
        submitForm() {
            this.errors = []

            if (this.start_planning === '') {
                this.errors.push('The start field is missing!')
            }
            
            if (this.end_planning === '') {
                this.errors.push('The end field is missing!')
            }
            if (this.selectedPeriods.length === 0) {
                this.errors.push('Please select at least one working period!')
            }
            if (!this.errors.length) {
                
                this.addPlanning();
                // this.addWorkingPeriods();
                // this.user = localStorage.getItem('user')
            }
        },
        async addPlanning() {
            this.$store.commit('setIsLoading', true);

            // Construct the payload for planning
            const planningPayload = {
                start: this.start_planning,
                end: this.end_planning,
                workingPeriods: []  // Initialize an array to hold working periods data
            };

            console.log('planningPayload:', planningPayload);
            console.log('selectedPeriods:', this.selectedPeriods);
            // Add working periods data to the payload
            for (const period of this.workingPeriods) {
                // console.log('period:', period, "workingPeriods:", this.workingPeriods);
                // console.log('period:', period);
                let isActive = false;
                if (this.isPeriodSelected(period)) {
                    isActive = true;
                } else {
                    isActive = false;
                }
                planningPayload.workingPeriods.push({
                    start: period.startDate,
                    end: period.endDate,  
                    name: period.label,
                    is_active: isActive, 
                });                
            }
            // Send a single PUT request with the combined payload
            console.log('planningPayload right before sending:', planningPayload);
            await axios
                .post('/api/v1/plannings/', planningPayload)
                .then(response => {
                    // console.log('Planning and working periods added:', response.data);
                })
                .catch(error => {
                    // Handle error
                    if (error.response) {
                        console.log(error.response.data);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                    console.log(error.config);
                });

            // Reset loading state
            this.$store.commit('setIsLoading', false);
            this.$emit('planningAdded');
            this.$emit('workingPeriodsAdded');
        }
    }
}
</script>

<style scoped>


</style>