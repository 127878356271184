export const infoTextAssignLicense =
    `Hier kommt bald ein Infotext.`

export const infoTextAddTeam =
    `Hier kommt bald ein Infotext.`

export const infoTextAddPlanning =
    `Hier kommt bald ein Infotext.`

export const infoTextAddVision =
    `Eine Vision ist ein langfristiges Ziel, das inspiriert und motiviert.`

export const infoTextAddMission =
    `Eine Mission ist eine Erklärung, die den Zweck und die Werte eines Unternehmens beschreibt.`

export const infoTextAddMoal =
    `Ein Moal ist ein übergeordnetes Ziel, zu dem Objectives und Key Results beitragen.`

export const infoTextAddObjective = 
    `Objectives sollen inspirierend und herausfordernd sein. 
    Ein gutes Objective ist klar formuliert, teamorientiert und auf den Zeitraum abgestimmt. 
    Das Objective wird qualitativ definiert und durch die verknüpften Keyrsults messbar gemacht. 
    Setzen Sie spezifische Ziele und benennen Sie Verantwortliche, um den Fortschritt zu verfolgen.`

export const infoTextAddObjectivePlanning =
    `Objectives sollen inspirierend und herausfordernd sein. 
    Ein gutes Objective ist klar formuliert, teamorientiert und auf den Zeitraum abgestimmt. 
    Das Objective wird qualitativ definiert und durch die verknüpften Keyrsults messbar gemacht. 
    Setzen Sie spezifische Ziele und benennen Sie Verantwortliche, um den Fortschritt zu verfolgen.`
    
export const infoTextAddObjectiveArchive = 
    `Objectives sollen inspirierend und herausfordernd sein. 
    Ein gutes Objective ist klar formuliert, teamorientiert und auf den Zeitraum abgestimmt. 
    Das Objective wird qualitativ definiert und durch die verknüpften Keyrsults messbar gemacht. 
    Setzen Sie spezifische Ziele und benennen Sie Verantwortliche, um den Fortschritt zu verfolgen.`

export const infoTextAddKeyresult =
    `Key Results sind messbare Ergebnisse, die den Fortschritt eines Objectives anzeigen. 
    Key Results sind spezifisch, messbar, erreichbar, relevant und zeitgebunden. 
    Key Results sind quantitativ definiert und helfen dabei, den Fortschritt zu verfolgen. 
    Setzen Sie spezifische Ziele und benennen Sie Verantwortliche, um den Fortschritt zu verfolgen.`

export const infoTextDetailKeyresult =
    `Hier kommt bald ein Infotext.`


export const infoTextAddCheckin =
    `Hier kommt bald ein Infotext.`


