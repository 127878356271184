<template>
    <div class="navbarItem">
      <!-- <router-link :to="link" class="button navbarButton is-light is-fullwidth" :class="[additionalClasses]">{{ text }}</router-link> -->
      <router-link :to="link" class="navbarButton" :class="[computedClass, additionalClasses]">
        {{ text }}
      </router-link>

    </div>
</template>

<script>

export default {
    name: 'Component_Sidebar_Button',
    props: {
      text: {
        type: String,
        required: false,
        default: ''
      },
      link: {
        type: String,
        required: false,
        default: '/'
      },
      isBold: {
        type: Boolean,
        default: false,
      },
      isActive: {
        type: Boolean,
        default: false,
      },
      additionalClasses: {
        type: [String, Array, Object],
        default: '',
      },
    },
    computed: {
      computedClass() {
        return this.isActive
        ? 'font-bold'
        : 'font-normal'
      }
    }     
}
</script>