<template>
    <strong class="is-flex is-justify-content-center is-align-items-center p-3">
        Check-in
    </strong>
    <table>
        <tbody>
            <tr>
                <td class="vertical-center">Name:</td>
                <td class="vertical-center">
                    <div>
                      {{ this.keyresult.name }}     
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Letzer Checkin:</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        {{ this.lastCheckin.value }} (am {{ this.redefineDate(this.lastCheckin.date) }})
                    </div>
                </td>
            </tr>
            <tr>
                <div>
                    &nbsp;
                </div>
            </tr>
            <tr>
                <td class="vertical-center">Neuer Wert:</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <input type="number" class="input-field-medium" v-model.number=editedKeyresult.value_current>
                        {{ displayUnit }}
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Nachricht:</td>
                <td class="vertical-center">
                    <div>
                        <textarea type="text" class="input-field-textarea" v-model="message" />
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Datum:</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <input type="datetime-local" class="input-field-big-2" v-model="date">
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="is-flex is-justify-content-center is-align-items-center p-3">
        <button class="button is-dark" @click="submitForm">Speichern</button>
    </div>


    <div class="notification is-danger mt-4" v-if="errors.length">
        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
    </div>
</template>

<script>
import axios from 'axios'
import { getToday, getTodayTime } from '@/utils/utils.js';



export default {
    name: 'Modal_ChangeKeyresult',
    emits: ['keyresultChanged', 'checkinAdded'],
    props: {
        keyresult: Object,
        lastCheckin: Object,
        team_users: Array,
    },
    data() {
        return {
            editedKeyresult: { ...this.keyresult },
            // lastCheckin: null,
            errors: [],
            // date: getToday(),
            // date: new Date().toISOString().split('T')[0], // Set default to current date and time
            // dateTIme: getTodayTime(),
            date: getTodayTime(),

        }
    },
    computed: {
        displayUnit() {
            return this.editedKeyresult.unit === 'custom'
                ? this.keyresult.custom_unit
                : this.editedKeyresult.unit
        },
    },
    created() {
        // this.getLastCheckin();
    },
    mounted() {
        // console.log("editedKeyresult:", this.editedKeyresult)
        // console.log('Date:', this.date)
    },
    methods: {
        submitForm() {
            this.errors = []
            if (this.editedKeyresult.value_current === '' || isNaN(+this.editedKeyresult.value_current)) {
                this.editedKeyresult.value_current = this.keyresult.value_current
            }
            // this.changeKeyResult()
            this.doCheckin()
        },
        async doCheckin() {
            this.$store.commit('setIsLoading', true)
            // console.log('this date:', this.date)
            const finalDate = this.appendCurrentSecondsMilliseconds(this.date);
            // console.log('finalDate:', finalDate)
            await axios
                .post(`/api/v1/checkins/`, 
                { 
                    keyresult: this.keyresult.id,
                    value: this.editedKeyresult.value_current,
                    // date: `${this.date}T00:00:00Z`
                    message: this.message,
                    date: finalDate,
                    // date: `${this.date}:04Z`

                }
                )
                .then(response => {
                this.$emit('keyresultChanged');
                this.$emit('checkinAdded')
                })
                .catch(error => {
                console.error('Error:', error)
                })
            this.$store.commit('setIsLoading', false)
        },
        appendCurrentSecondsMilliseconds(userDateStr) {
            const userDate = new Date(userDateStr);
            const now = new Date();
            userDate.setSeconds(now.getSeconds());
            userDate.setMilliseconds(now.getMilliseconds());
            return userDate.toISOString();
        },

        redefineDate(date) {
            return new Date(date).toLocaleDateString('de-DE');
        },
    }
}
</script>

<style scoped>


</style>