<template>
    <div class="flex flex-col items-center">
        <div v-if="permissions.includes(`olp_change_moal_${moal.id}`) && permissions.includes('product.add_moal')">
            <button @click="isModalOpen = true" class="actionButtonBlack m-2 font-bold">{{ moal.name }}</button>
            <Teleport to="#modal">
                <Transition name="modal">
                    <div v-if="isModalOpen" class="modal-bg" @click.self="isModalOpen = false">
                        <div class="modal">
                            <Component_Modal_InfoButton :infoText="infoTextAddMoal" />
                            <button @click="isModalOpen = false" class="close-btn">x</button>
                            <Modal_ChangeMoal
                            v-if="permissions.includes(`olp_change_moal_${moal.id}`)"
                            v-bind:moal="moal"
                            v-on:moalChanged="handleMoalChanged"
                            />
                        </div>
                    </div>
                </Transition>
            </Teleport>
        </div>
        <strong v-else class="m-2 overview-header">
          {{ moal.name }}
      </strong>
    </div>

    <!-- <div class="overview-background-wrapper">
        <div class="overview-background bg-[#5ca6f0]" v-for="objective in filteredObjectives" v-bind:key="objective.id">
            <div class="actually-centered">
                <button class="button is-dark round-button-small">x{{ objective.multiplier_objective_moals }}</button>
                <Component_Overview_Objective
                        v-bind:objective="objective"
                        v-bind:keyresults="keyresults"
                        v-bind:permissions="permissions"
                >
                </Component_Overview_Objective>
            </div>
        </div>
    </div> -->
</template>


<script>
import axios from 'axios'
import { mapState } from 'vuex'
import Component_Overview_Objective from '@/components/Overview/Component_Overview_Objective.vue'
import Modal_ChangeMoal from '@/components/Modals/Modal_ChangeMoal.vue'
import Component_Modal_InfoButton from '@/components/Component_Modal_InfoButton.vue'
import { infoTextAddMoal } from '@/utils/infotexts'


export default {
    name: 'Component_Overview_Moal',
    emits: ['moalChanged'],
    components:
    {
        Component_Overview_Objective,
        Modal_ChangeMoal,
        Component_Modal_InfoButton,
    },
    props: {
        moal: Object,
        objectives: Array,
        keyresults: Array,
        permissions: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            filteredObjectives: [],
            // objectives: [],
            isModalOpen: false,
            multipliersByObjective: {},
            testvariable: null,
            infoTextAddMoal: infoTextAddMoal,
        }
    },
    mounted() {
        console.log("In Component_Overview_Moal mounted");
        // this.getObjectives();
        this.filterObjectives();
    },
    methods: { 
        filterObjectives() {
            // this.filteredObjectives = this.objectives.filter(objective => objective.moals === this.moal.id);
            this.filteredObjectives = this.objectives.filter(objective => objective.moals.some(moalID => moalID === this.moal.id)
            );
            this.filterWeights(this.filteredObjectives);
        },
        // async getObjectives() {
        //     this.$store.commit('setIsLoading', true);
        //     try {
        //         const response = await axios.get(`/api/v1/objectives/?moal_id=${this.moal.id}`);
        //         this.objectives = response.data;
        //         // for (const objective of this.objectives) {
        //         //     this.getMultiplier(objective, this.moal);
        //         // }
        //         console.log("Fetched objectives for moal", this.moal.name, ":", this.objectives);
        //         this.filterWeights(this.objectives);
        //     } catch (error) {
        //         console.error('Error fetching moals:', error);
        //     }
        //     this.$store.commit('setIsLoading', false);
        // },
        filterWeights(objectivess) {
            for (const objective of objectivess) {
                // console.log("Unfiltered weights for objective", objective.id, "and moal", this.moal.id, ":", objective.multiplier_objective_moals);
                // Ensure objective.multiplier_objective_moals is an array before filtering
                if (!Array.isArray(objective.multiplier_objective_moals) || objective.multiplier_objective_moals.length === 0) {
                    console.error('multiplier_objective_moals is not an array or is empty', objective.multiplier_objective_moals);
                    continue; // Skip to the next objective if not an array or empty
                }
                objective.multiplier_objective_moals = objective.multiplier_objective_moals.filter(entry => entry.moal === this.moal.id);
                // console.log("Filtered objective.multiplier_objective_moals", objective.multiplier_objective_moals);
                if (objective.multiplier_objective_moals.length === 0) {
                    continue; // Skip to the next objective if no matching entries
                }
                // Assuming the intention is to store the weight of the first item, ensure there's an item to access
                if (objective.multiplier_objective_moals.length > 0) {
                    objective.multiplier_objective_moals = objective.multiplier_objective_moals[0].weight;
                } else {
                    // Handle the case where no items match the filter
                    console.error('No matching moals found for objective', objective.id);
                    objective.multiplier_objective_moals = null; // or some default value
                }
            }
        },
        handleMoalChanged() {
            console.log("In handleMoalChanged");
            this.isModalOpen = false;
            this.$emit('moalChanged');
        },
        // async getMultiplier(objective, moal) {
        //     this.$store.commit('setIsLoading', true);
        //     console.log("Fetching multiplier for objective", objective.id, "and moal", moal.id);
        //     try {
        //         const fetchedMultiplier = await axios.get(`/api/v1/multiplier-objective-moals/${moal.id}/${objective.id}/`);
        //         this.$set(this.multipliersByObjective, objective.id, fetchedMultiplier);
        //         console.log("Fetched multiplier for objective", objective.id, "and moal", moal.id, ":", fetchedMultiplier);
        //         // this.multiplier = response.data;
        //     } catch (error) {
        //         console.error("Error fetching multiplier:", error);
        //         // Handle error appropriately
        //     }
        //     this.$store.commit('setIsLoading', false);
        // },
    }
}
</script>