<template>
  <!-- <nav class="fixed top-0 left-0 h-screen w-55 flex flex-col bg-gray-100 shadow"> -->
  <nav class="h-full pt-5 pb-5 pl-1 pr-1 flex flex-col bg-gray-200 dark:bg-gray-800 shadow">
    <!-- <transition name="buttons-fadeout"> -->
      <!-- <div v-if="showSidebar" class="transition-all duration-1000 ease-in-out" :class="isSidebarOpen ? 'w-64' : 'w-4'">-->
    <div class="transition-all ease-in-out" :class="showSidebar ? 'delay-300 duration-1000' : 'pointer-events-none opacity-0  duration-500'">
    <!-- <div> -->

      <!-- <div v-show="isSidebarOpen" class="sidebar-content"> -->

    <!-- <div class="navbarItem">
      &nbsp;
    </div> -->

    <!-- <div>
      <Component_Sidebar_Icon :icon="HomeIcon" />
    </div> -->

    <!-- <div class="navbarItem">
      <a class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbar-menu" @click="showMobileMenu = !showMobileMenu">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div> -->

    <!-- <Component_Sidebar_Button v-bind:text="'vanta OKR'" v-bind:link="'/overview'" /> -->
    <!-- <div class="text-right">
      <SidebarToggleButton 
        :isOpen="isSidebarOpen"
        @toggle="toggleSidebar"
      />
    </div> -->
    <!-- <Component_Sidebar_Button :text="'VANTA OKR'" :link="'/okrs'" :isActive="$route.path === '/okrs'" :additionalClasses="'font-bold'"/> -->

    <!-- <Component_Sidebar_Button :text="'Übersicht'" :link="'/overview'" /> -->
    <Component_Sidebar_Button :text="'OKR'" :link="'/okrs'" :isActive="$route.path === '/okrs'" :isBold="false"/>
    <Component_Sidebar_Button :text="'Teams'" :link="'/teams'" :isActive="$route.path === '/teams'" :isBold="false"/>
    <Component_Sidebar_Button :text="'Firmenübersicht'" :link="'/company'" :isActive="$route.path === '/company'" :isBold="false"/>

    <Component_Sidebar_Button :text="'OKR Archiv'" :link="'/okrs-archive'" :isActive="$route.path === '/okrs-archive'" :isBold="false"/>
    <Component_Sidebar_Button :text="'Changelog'" :link="'/changelog'" :isActive="$route.path === '/changelog'" :isBold="false" v-if="permissions.includes('product.view_changelog')" />
    <Component_Sidebar_Button :text="'Lizenzverwaltung'" :link="'/licenses'" :isActive="$route.path === '/licenses'" :isBold="false" v-if="permissions.includes('product.view_license')"/>

    <Component_Sidebar_Button :text="'Profil'" :link="'/my-account'" :isActive="$route.path === '/my-account'" :isBold="false"/>

    <!-- <Component_Sidebar_Button :text="'Test DataAnnotationA'" :link="'/test-data-annotation-a'" :isBold="false"/>
    <Component_Sidebar_Button :text="'Test DataAnnotationB'" :link="'/test-data-annotation-b'" :isBold="false"/> -->


    <div class="navbarItem">
      <button @click="logout()" class="navbarButton">Log out</button>
    </div>
    <!-- TODO make these seperated and not with nbsp -->
    <div class="navbarItem"> &nbsp;</div> 
    <!-- <div class="navbarItem"> &nbsp;</div> -->

    <Component_Sidebar_Button :text="'Datenschutzerklärung'" :link="'/privacy-statement'" :isActive="$route.path === '/privacy-statement'" :isBold="false" :additionalClasses="'italic'"/>
    <!-- <Component_Sidebar_Button :text="'AGB'" :link="'/terms-and-conditions'" :isBold="false" :additionalClasses="'italic'"/> -->
    <Component_Sidebar_Button :text="'Impressum'" :link="'/imprint'" :isActive="$route.path === '/imprint'" :isBold="false" :additionalClasses="'italic'"/>


    </div>
  </nav>
</template>

<script>
import axios from 'axios'
import { HomeIcon } from '@heroicons/vue/24/outline';
import { MagnifyingGlassIcon } from "@heroicons/vue/24/outline";

import Component_Sidebar_Icon from '@/components/Sidebar/Component_Sidebar_Icon.vue';
import Component_Sidebar_Button from './Component_Sidebar_Button.vue';
import SidebarToggleButton from '@/components/Sidebar/Component_ToggleSidebarButton.vue';
import Search from '@/views/Unused_Search.vue';


export default {
  name: 'Component_Sidebar',
  emits: ['toggle-sidebar'],
  components: {
    Component_Sidebar_Icon,
    Component_Sidebar_Button,
    SidebarToggleButton,
    MagnifyingGlassIcon,
  },
  props: {
    showSidebar: {
      type: Boolean,
      default: false
    },
    showMobileMenu: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      HomeIcon,
      // MagnifyingGlassIcon,
    }
  },
  computed: {
    permissions() {
      return this.$store.state.permissions;
    },
  },
  mounted() {
    // this.cart = this.$store.state.cart
    // this.theme = this.$store.state.theme
    // console.log("Permissions:", this.permissions)
  },
  methods: {
    logout() {
      axios.defaults.headers.common["Authorization"] = ""

      localStorage.removeItem("token")
      localStorage.removeItem("username")
      localStorage.removeItem("userid")

      this.$store.commit('removeToken')

      this.$router.push('/log-in-to-okr')
    },
    toggleSidebar() {
      this.$emit('toggle-sidebar')
    },
  }
}
</script>
<style lang="scss">

// .buttons-fadeout-enter-active,
// .buttons-fadeout-leave-active {
//   transition: opacity 0.5s ease;
//   opacity: 1;
// }

// .buttons-fadeout-enter {
//   opacity: 1;
// }
// .buttons-fadeout-leave-to {
//   opacity: 0;
// }

// .buttons-fadeout-enter-active,
// .buttons-fadeout-leave-active {
//   transition: transform 0.5s ease;
// }

// .buttons-fadeout-enter,
// .buttons-fadeout-leave-to {
//   transform: translateX(-100%);
// }

</style>