<template>
  <div>
    <div class="flex align-center justify-between">
        <strong>Checkin Details</strong>

        <button class="text-right" @click="$emit('close')">x</button>
    </div>
    <p>Datum: {{ checkin.date }}</p>
    <p>Wert: {{ checkin.value }}</p>
    <p>Nachricht: {{ checkin.message }}</p>

    <button class="actionButton" @click="deleteCheckin()">Checkin löschen</button>

  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import axios from 'axios';  

const props = defineProps({
  checkin: {
    type: Object,
    required: true
  }
});

const emit = defineEmits(['checkinDeleted']);

async function deleteCheckin() {
  console.log('Deleting checkin:', props.checkin.id);
  try {
    const response = await axios.delete(`/api/v1/checkins/${props.checkin.id}/`);
    emit('checkinDeleted');
  } catch (error) {
    console.error('Error deleting checkin:', error);
  }
}
</script>