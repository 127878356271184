<template>
    <div class="notification is-info mt-4 text-center" v-if="!isPlanningActive">
        Achtung: Sie machen gerade eine Eintragung <br>
        außerhalb der dafür vorgesehenen Planningzeit.
    </div>
    <strong class="is-flex is-justify-content-center is-align-items-center p-3">
        Objective ändern
    </strong>
    <table>
        <tbody>
            <tr>
                <td class="vertical-center">Name:</td>
                <td class="vertical-center">
                    <div>
                        <textarea type="text" class="input-field-textarea" v-model="editedObjective.name" />
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Team:</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <select class="input-field-big" v-model="editedObjective.team"> 
                            <option v-for="team in teamsTeamLead" :value="team.id">{{ team.name }}</option>
                        </select>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Status:</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <select class="input-field-big" v-model="editedObjective.status">
                            <option value="Not started">Not started</option>
                            <option value="Off track">Off track</option>
                            <option value="Progressing">Progressing</option>
                            <option value="On track">On track</option>
                        </select>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Moals:</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <select class="input-field-big" multiple v-model="editedObjective.moals">
                            <option v-for="moal in moals_fetched" :value="moal.id">{{ moal.name }}</option>
                        </select>
                    </div>
                </td>
            </tr>
            <tr v-if="editedObjective.moals && editedObjective.moals.length > 0">
                <td>
                    &nbsp;
                </td>
            </tr>
            <tr v-if="editedObjective.moals && editedObjective.moals.length > 0">
                <td class="vertical-center">
                    <strong>
                        Gewichte MOALs:
                    </strong>
                </td>
            </tr>
            <tr v-for="(moalId, index) in editedObjective.moals" :key="index">
                <td class="vertical-center">{{ findMoalName(moalId) }}:</td>
                <td class="vertical-center">
                    <div>
                        <input 
                            type="number" 
                            class="input-field-big" 
                            v-model="moalWeights[moalId]" 
                        />
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Grenzwert Grün (%):</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <input type="number" class="input-field-big" v-model.number="editedObjective.threshold_green" @input="validatePercentage('threshold_green')">
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Grenzwert Rot (%):</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <input type="number" class="input-field-big" v-model.number="editedObjective.threshold_red" @input="validatePercentage('threshold_red')">
                    </div>
                </td>
            </tr>
            <tr v-if="keyresults.length > 0">
                <td>
                    &nbsp;
                </td>
            </tr>
            <tr v-if="keyresults.length > 0">
                <td class="vertical-center">
                    <strong>
                        Gewichte Keyresults:
                    </strong>
                </td>
            </tr>
            <tr v-for="keyresult in editedKeyresults" :key="keyresult.id">
                <td class="vertical-center">{{ keyresult.name }}:</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <input type="number" class="input-field-big" v-model.number="keyresult.weight_multiplier">
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Arbeitszeitraum:</td>
                <td class="vertical-center">
                    <div class="radio-group">
                        <div v-for="period in activeWorkingPeriods" :key="period.id" class="radio-option">
                            <input
                            type="radio"
                            :value="period"
                            :id="`period-${period.value}`"
                            v-model="selectedWorkingPeriod"
                            name="workingPeriod"
                            >
                            <label :for="`period-${period.value}`" class="spaced"> {{ period.name }}</label>
                        </div>
                    </div>
                </td> 
            </tr>
            <tr>
                <td>
                    &nbsp;
                </td>
            </tr>
        </tbody>
    </table>
    <div class="is-flex is-justify-content-center is-align-items-center p-3">
        <button class="button is-dark" @click="submitForm">Speichern</button>
    </div>
    <div class="notification is-danger mt-4" v-if="errors.length">
        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
    </div>
    <div class="is-flex is-justify-content-center is-align-items-center p-3">
        <button class="button is-danger" @click="deleteObjective">Löschen</button>
    </div>
    <!-- <div class="flex-center">
        <span class="help-icon" @mouseover="showHelpText = true" @mouseleave="showHelpText = false">
            ?
        </span>
        <span v-if="showHelpText" class="help-text">Your help text goes here</span>
    </div> -->
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'

export default {
    name: 'Modal_ChangeObjective',
    emits: ['objectiveChanged', 'keyresultChanged'], 
    props: {
        objective: Object,
        keyresults: Array, 
        activeWorkingPeriods: Array,
        isPlanningActive: Boolean,
    },
    data() {
        return {
            editedObjective: { ...this.objective },
            editedKeyresults: JSON.parse(JSON.stringify(this.keyresults)),
            errors: [],
            moals_fetched: [],
            moalWeights: {},
            customSelected: false,
            showHelpText: false,
            selectedWorkingPeriod: '',
        }
    },
    mounted() {
        this.fetchTeams();
        this.getMOALs();
        this.preselectWorkingPeriod();
        this.initializeMoalWeights();
    },
    computed: {
        ...mapState([
            'teamsTeamLead',
        ])
    },
    methods: {
        submitForm() {
            this.errors = []

            if (this.editedObjective.name === '') {
                this.errors.push('The name field is missing!')
            }
            
            if (this.editedObjective.team === '') {
                this.errors.push('The team field is missing!')
            }
            if (this.editedObjective.status === '') {
                this.errors.push('The status field is missing!')
            }

            if (this.editedObjective.threshold_red >= this.editedObjective.threshold_green) {
                this.errors.push('Der Grenzwert zu Rot muss niedriger als der Grenzwert zu grün sein')
            }
            for (let keyresult of this.editedKeyresults) {
                if (typeof keyresult === 'object' && keyresult !== null) {
                    const weight = parseFloat(keyresult.weight_multiplier);
                    if (isNaN(weight) || weight === '') {
                        keyresult.weight_multiplier = 1;
                    }
                }
            }
            if (!this.errors.length) {
                this.progress = parseInt(this.progress);
                if (this.unit === 'custom') {
                    this.unit = this.customInput
                };
                this.changeObjective();
                this.changeKeyresults();
                this.unit = '%';
            }
        },
        async preselectWorkingPeriod() {
            if (!this.objective.is_active) {
                this.selectedWorkingPeriod = this.activeWorkingPeriods[0];
            }
            const workingPeriod = this.activeWorkingPeriods.find(period => period.id === this.objective.working_period);
            this.selectedWorkingPeriod = workingPeriod;
        },
        async changeObjective() {
            this.$store.commit('setIsLoading', true)

            await axios
                .patch(`/api/v1/objectives/${this.objective.id}/`, 
                {
                name: this.editedObjective.name,
                team: this.editedObjective.team, 
                owner: this.editedObjective.owner,
                status: this.editedObjective.status,
                moals: this.editedObjective.moals,
                multiplier_objective_moal: this.prepareMoalsForSubmission(),
                working_period: this.selectedWorkingPeriod.id,
                threshold_green: this.editedObjective.threshold_green,
                threshold_red: this.editedObjective.threshold_red,
                },
                )
                .then(response => {
                this.$emit('objectiveChanged');
                })
                .catch(error => {
                    if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    } else if (error.request) {
                    console.log(error.request);
                    } else {
                    console.log('Error', error.message);
                    }
                    console.log(error.config);
                })
 this.$store.commit('setIsLoading', false)
        },
        async changeKeyresults() {
            this.$store.commit('setIsLoading', true)
            for (let keyresult of this.editedKeyresults) {
                await axios
                    .put(`/api/v1/keyresults/${keyresult.id}/`, 
                    {
                    weight_multiplier: keyresult.weight_multiplier,
                    },
                    )
                    .then(response => {
                    this.$emit('keyresultChanged');
                    })
                    .catch(error => {
                    console.error('Error:', error)
                    })
            }
            this.$store.commit('setIsLoading', false)
        },
        async deleteObjective() {
            this.$store.commit('setIsLoading', true)
            await axios
                .delete(`/api/v1/objectives/${this.objective.id}/`)
                .then(response => {
                this.$emit('objectiveChanged');
                })
                .catch(error => {
                    if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    } else if (error.request) {
                    console.log(error.request);
                    } else {
                    console.log('Error', error.message);
                    }
                    console.log(error.config);
                })
            this.$store.commit('setIsLoading', false)
        },
        fetchTeams() {
            this.$store.dispatch('fetchTeamsTeamlead')
                .then(() => {
                });
        },
        async getMOALs() {
            this.$store.commit('setIsLoading', true);
            try {
                const response = await axios.get(`/api/v1/moals/`);
                this.moals_fetched = response.data;
                console.log('MOALs:', this.moals_fetched)

            } catch (error) {
                console.error('Error fetching moals:', error);
            }
            this.$store.commit('setIsLoading', false);
        },
        findMoalName(id) {
            const moal = this.moals_fetched.find(moal => moal.id === id);
            return moal ? moal.name : 'Unknown';
        },
        initializeMoalWeights() {
            this.editedObjective.moals.forEach(moalId => {
                const index = this.editedObjective.multiplier_objective_moals.findIndex(moal => moal.moal === moalId);
                if (index !== -1) {
                    this.moalWeights[moalId] = this.editedObjective.multiplier_objective_moals[index].weight;
                } else {
                    this.moalWeights[moalId] = 0; // Default value if not found
                }
            });
        },
        prepareMoalsForSubmission() {
            const connections = [];
            for (const moalId in this.moalWeights) {
                connections.push({
                    moal: moalId,
                    weight: this.moalWeights[moalId]
                });
            }
            return connections;
        },
        handleCustomUnit() {
            this.validateProgress()
            if (this.unit === ' ') {
                this.customSelected = true;
            } else {
                this.customSelected = false;
            }
        },
        validatePercentage(field) {
            if (this[field] < 0) {
                this.field = 0;
            } else if (this[field] > 100) {
                this[field] = 100;
            }
        },
    },
    watch: {
        'editedObjective.moals': {
            handler(newVal, oldVal) {
                // Initialize weight for newly added moals
                newVal.forEach(id => {
                    if (!this.moalWeights[id]) {
                        this.moalWeights[id] = 1; // Default weight of 1
                    }
                });
                // Remove weight for deselected moals
                for (const id in this.moalWeights) {
                    if (!newVal.includes(Number(id))) {
                        delete this.moalWeights[id];
                    }
                }
            },
            deep: true
        }
    }
}
</script>
