<template>
  <div class="flex flex-wrap" v-if="!isLoading">
      <!-- <div class="column is-12"> -->
        <!-- <h2 class="is-size-2 has-text-centered pb-6">Sustevia</h2> -->
      <!-- </div> -->
      <h1>Teams</h1>

      <table class="table w-full no-header-border table-fixed bg-transparent">
        <thead>
          <tr class="bg-transparent">
            <td class="text-left   w-[150%] no-header-border font-weight-bold "><span>Teamname</span></td>
            <td class="text-center w-[100%] no-header-border font-weight-bold "><span>Teamlead</span></td>
            <td class="text-center w-[100%] no-header-border font-weight-bold "><span>Mitglieder</span></td>
            <td class="text-center w-[100%] no-header-border font-weight-bold "><span>Check-in Tag</span></td>
            <td class="text-center w-[100%] no-header-border font-weight-bold "><span>Aktionen</span></td>
          </tr>
        </thead>

        <tbody>
          <tr class="tableRow" v-for="team in teamsAll" v-bind:key="team.id">
            <Component_Teams_Team
                v-bind:permissions="permissions"
                v-bind:team="team"
                v-bind:users="users"
                v-bind:users_teamlead="users_teamlead"
                v-on:teamChanged="handleTeamChanged" 
                v-on:teamDeleted="handleTeamDeleted"
                />
          </tr>

          <tr class="flex">
            <div v-if="permissions.includes('product.add_team')" class="flex mt-[15px]">
              <button @click="isAddTeamModalOpen = true" >
                <svg class="h-10 w-10  hover:rotate-90 transition-transform duration-300" fill="black" viewBox="0 0 24 24" stroke="black">
                  <circle cx="12" cy="12" r="10" stroke="black" stroke-width="0"/>
                  <path stroke-linecap="round" stroke="white" stroke-linejoin="round" stroke-width="1" d="M12 8v8m-4-4h8" />
                </svg>
              </button>
              <div class="vertical-center-flex">
                Team hinzufügen
              </div>
            </div>
            <Teleport to="#modal">
              <Transition name="modal">
                <div v-if="isAddTeamModalOpen" class="modal-bg" @click.self="isAddTeamModalOpen = false">
                  <div class="modal">
                    <Component_Modal_InfoButton :infoText="infoTextAddTeam" />
                    <button @click="isAddTeamModalOpen = false" class="close-btn">x</button>
                    <Modal_AddTeam
                      v-if="permissions.includes('product.add_team')"
                      v-bind:permissions="permissions"
                      v-bind:users="users"
                      v-bind:users_teamlead="users_teamlead"
                      v-on:teamAdded="handleTeamAdded" 
                    />                    
                  </div>
                </div>
              </Transition>
            </Teleport>
          </tr>
        </tbody>
    </table>
  </div>
</template>

<script>
// import axios from '@/services/axiosConfig'; // Import the configured Axios instance
import axios from 'axios'
import { mapState } from 'vuex'

import Component_Teams_Team from '@/components/teams/Component_Teams_Team.vue'
// import ComponentTeamsAddTeam from '@/components/ComponentTeamsAddTeam'
import Modal_AddTeam from '@/components/Modals/Modal_AddTeam'
import Modal_ChangeTeam from '@/components/Modals/Modal_ChangeTeam'
import { getPermissions } from '@/utils/utils.js'
import Component_Modal_InfoButton from '@/components/Component_Modal_InfoButton.vue';
import { infoTextAddTeam } from '@/utils/infotexts'

// import axios from '@/services/axiosConfig'; // Import the configured Axios instance
// import { getCsrfToken } from '@/utils/csrf'; // Import the CSRF utility function



export default {
//   name: 'Teams',
  components: {
    Component_Teams_Team,
    // ComponentTeamsAddTeam,
    Modal_AddTeam,
    Modal_ChangeTeam,
    Component_Modal_InfoButton,
  },
  emits: ['requestPermissions'],
  data() {
    return {
        // teams: [],
        teamsAll: [],
        users: [],
        users_teamlead: [],
        infoTextAddTeam: infoTextAddTeam,
        isAddTeamModalOpen: false,
        isLoading: true,
    }
  },
  computed: {
    permissions() {
      return this.$store.state.permissions;
    },
  },
  created() {
    this.$store.dispatch('fetchPermissions');
  },
  mounted() {
    document.title = 'Home | Teams'
    this.loadData();
  },
  methods: {
    async loadData() {
      await Promise.all([
        this.fetchTeamsAll(),
        this.getUsers(),
        this.getUsersTeamLead(),
      ]);
      this.isLoading = false;
      this.$store.dispatch('fetchPermissions')
    },
    async fetchTeamsAll() {
      this.isLoading = true;
      try {
        // const csrfToken = getCsrfToken(); // Retrieve the CSRF token
        // console.log('csrfToken:', csrfToken);
        const response = await axios.get('/api/v1/teams', {
          params: {
            teamlead_check: false,
          },
        });
        this.teamsAll = response.data;
      } catch (error) {
        console.error('Error fetching teams:', error);
      } finally {
        this.isLoading = false;
      }
    },
    async handleTeamDeleted() {
      // console.log('Team deleted:', productId);
      // Fetch products again after a product is deleted
      await this.fetchTeamsAll();
    },
    async handleTeamChanged() {
      // Fetch products again after a product is deleted
      await this.fetchTeamsAll();
    },
    async getUsers() {
        // const token = localStorage.getItem('token');
        // console.log('Token:', token);
        try {
            const response = await axios.get(`/api/v1/company-users`);
            console.log('response getUsers:', response);
            this.users = response.data;
            // console.log("this.users:", this.users)
        } catch (error) {
            console.error('Error getting users:', error);
            console.error('Error getting users:', error.response.data);
        }
    },
    async getUsersTeamLead() {
        const token = localStorage.getItem('token');
        // console.log('Token:', token);
        try {
            const response = await axios.get(`/api/v1/company-users`, {
            headers: {
                'Authorization': 'Token ' + token,
            },
            params: {
              teamlead_filter: true
            }
        });
        // console.log('response getUsers teamlead:', response);
        this.users_teamlead = response.data;
            // console.log("this.users:", this.users)
        } catch (error) {
            console.error('Error getting users:', error);
            console.error('Error getting users:', error.response.data);
        }
    },
    async handleTeamAdded() {
      this.isAddTeamModalOpen = false;
      await this.loadData();
      // console.log('before emit requestPermissions');
      // this.$emit('requestPermissions');
      // console.log('after emit requestPermissions');
    },
  }
}
</script>

