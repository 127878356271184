<template>
    <tr>
        <!-- <td v-if="permissions.includes('product.add_keyresult')" class="okrFirstColumn w-[3%]"></td> -->
        <!-- <td class="okrSecondColumn w-[3%]"></td> -->
        <td class="font-bold w-[40%] max-w-[80%] px-3"><span class="text-vantablue-500 ">Team</span><span class="font-normal"> | </span><span>Objective</span><span class="font-normal"> | Keyresult</span></td>              
        <!-- <td class="font-bold w-[10%] max-w-[80%] px-3"><span>Team</span></td> -->
        <td class="font-bold  w-[10%] max-w-[20%] px-3"><span>Owner</span></td>
        <td class="font-bold w-[10%] max-w-[20%] px-3 text-center"><span >Progress</span></td>
        <!-- <td class="font-bold text-black w-[90%] text-center">Status</td> -->
        <td class="font-bold  w-[10%] max-w-[20%] px-3 text-center"><span >Zeitraum</span></td>
        <td class="font-bold w-[10%] max-w-[20%] px-3 text-center"><span>Aktionen</span></td>
    </tr>
</template>

<script>

export default {
    name: 'Component_OKR_Header',
    computed: {
        permissions() {
            return this.$store.state.permissions;
        },
    }
}
</script>
