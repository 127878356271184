import axios from 'axios';


export async function getPermissions() {
  // console.log('inside get permissions util function');
  try {
    const response = await axios.get('/api/v1/me/permissions');
    console.log('permissions util function:', response.data.permissions);
    return response.data.permissions;
  } catch (error) {
    console.error('Error fetching permissions:', error);
    throw error;  // Optionally re-throw the error if you want to handle it further up the call stack
  }
}

export function progressBarBackgroundColor(objectiveProgress, threshold_green, threshold_red) {
  return 'bg-gray-200';
  if (objectiveProgress < threshold_red / 100) {
      return '#8B0000';
    // } else if (objectiveProgress < threshold_yellow / 100) {
    //   return '#cc8400';
    } else if (objectiveProgress < threshold_green / 100) {
      return '#cccc00';
    } else {
      return '#006600';
    }
}

export function progressBarColor(progress, threshold_green, threshold_red) {
  // console.log("progress:", progress, "threshold_green:", threshold_green, "threshold_red:", threshold_red);
  // return 'bg-gray-500';

  if (progress == null) 
    return 'bg-white';
  if (progress < threshold_red) {
      return 'bg-red';
  // } else if (objectiveProgress < threshold_yellow/100) {
  //     return 'orange';
  } else if (progress < threshold_green) {
      return 'bg-yellow';
  } else {
    return 'bg-green';
  }
}

export function calculateKeyresultProgress(keyresult) {
  // console.log("keyresult okok:", keyresult);
  // Ensure goal, start, and value_current are numbers for calculation
  const goal = parseFloat(keyresult.goal);
  const startValue = parseFloat(keyresult.start);
  const currentValue = parseFloat(keyresult.value_current);
  // console.log("id:", keyresult.id)
  const index = keyresult.id;

  // Check if objective and working_period exist
  if (!keyresult.objective) {
    console.error(`Objective or working period is missing for keyresult at index ${index}`);
    return;
  }

  const workingPeriod = keyresult.objective.working_period;

  // Check if start and end times exist
  if (!keyresult.working_period_start || !keyresult.working_period_end) {
    console.error(`Start or end time is missing for working period at index ${index}`);
    return;
  }

  // Get the start and end times from the working period
  const startTime = new Date(keyresult.working_period_start);
  const endTime = new Date(keyresult.working_period_end);

  // Mocking Data
  // const mocking_testTime = new Date();
  // mocking_testTime.setMonth(mocking_testTime.getMonth() + 0);

  const totalTimeSpan = endTime - startTime;
  const elapsedTimeSpan = Date.now() - startTime.getTime();

  // Mocking Data
  // const elapsedTimeSpan = mocking_testTime - startTime.getTime();

  const expectedProgressRatio = totalTimeSpan > 0 ? elapsedTimeSpan / totalTimeSpan : 0;
  const expectedValue = startValue + (goal - startValue) * expectedProgressRatio;

  let actualProgress = 0;
  if (goal - startValue !== 0) {
    actualProgress = ((currentValue - startValue) / (goal - startValue)) * 100;
  }

  const expectedProgress = ((expectedValue - startValue) / (goal - startValue)) * 100;

  let progress = 0;
  if (expectedProgress !== 0) {
    keyresult.progress = actualProgress / expectedProgress * 100;
  }

  return {
    ...keyresult,
    // progress,
    // actual_progress: actualProgress,
    // expected_progress: expectedProgress
  };
}

export function calculateObjectivePercentage(keyresults) {
    let percentage = 0;
    let totalPercentage = 0;
    let totalKeyresults = 0;
    keyresults.forEach(keyresult => {
        totalPercentage += keyresult.progress_percentage;
        totalKeyresults += 1;
    });
    if (totalKeyresults > 0) {
        percentage = totalPercentage / totalKeyresults;
    }
    return percentage;
}



export function generateWorkingPeriods() {
  const workingPeriods = [];
  // const currentDate = new Date('2024-05-10');
  const currentDate = new Date();

  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // 1-12
  const currentDay = currentDate.getDate(); // 1-31

  // Calculate the day of the year
  const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const isLeapYear = (year) => (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
  if (isLeapYear(currentYear)) {
    daysInMonth[1] = 29;
  }

  let dayOfYear = currentDay;
  for (let i = 0; i < currentMonth - 1; i++) {
    dayOfYear += daysInMonth[i];
  }

  // Calculate the closest quarter
  const daysInQuarter = 365 / 4; // Assumption
  const closestQuarter = Math.round(dayOfYear / daysInQuarter) % 4 + 1;
  const closestYear = dayOfYear >= 365 - daysInQuarter / 2 ? currentYear + 1 : currentYear;


  // const closestQuarter = ((Math.round(currentMonth / 3)) + 1) % 4;
  // (closestQuarter - 1) * 3
  const closestQuarterStartMonth = (closestQuarter - 1) * 3 + 1;
  const closestQuarterStartDate = new Date(closestYear, closestQuarterStartMonth, 1);


  // Determine the semester based on the current quarter
  // const currentSemester = closestQuarter <= 2 ? 1 : 2;
  // const semesterStartDate = new Date(currentYear, currentSemester === 1 ? 0 : 6, 1);

  // Quarter period
  

  let yearSixMonthsLater = closestYear;
  if (closestQuarter >= 3) {
    yearSixMonthsLater = currentYear + 1;
  }
  // Semester period
  const semesterEndDate = new Date(yearSixMonthsLater, (closestQuarterStartMonth + 6) % 12, 1);
  

  // Year period
  const yearSpanEndDate = new Date(closestYear + 1, closestQuarterStartMonth, 1);
  let endyear_yearperiod = currentYear + 1;
  if (closestQuarter === 1) {
    endyear_yearperiod = currentYear;
  } 

  workingPeriods.push({
    // label: ` Q${closestQuarter} ${closestYear.toString().slice(-2)}`,
    label: `Q${closestQuarter}`,
    startDate: formatDate(closestQuarterStartDate),
    endDate: formatDate(new Date(closestQuarterStartDate.getFullYear(), closestQuarterStartDate.getMonth() + 3, 1))
  });
  workingPeriods.push({
    label: `Q${closestQuarter}  - Q${(closestQuarter + 1) % 4} `,
    startDate: formatDate(closestQuarterStartDate),
    endDate: formatDate(semesterEndDate)
  });
  workingPeriods.push({
    // label: `Q${closestQuarter} ${closestYear.toString().slice(-2)} - Q${(closestQuarter + 2) % 4 + 1} ${endyear_yearperiod.toString().slice(-2)}`,
    label: `Q${closestQuarter} - Q${(closestQuarter + 2) % 4 + 1}`,
    startDate: formatDate(closestQuarterStartDate),
    endDate: formatDate(yearSpanEndDate)
  });
  
  console.log('workingPeriods after push:', workingPeriods);

  return workingPeriods;
}

export function formatDate(date) {
  const year = date.getFullYear();
  const month = date.getMonth().toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function getToday() {
  const today = new Date();
  const year = today.getFullYear();
  const month = (`0${today.getMonth() + 1}`).slice(-2); // Add leading zero if needed
  const day = (`0${today.getDate()}`).slice(-2); // Add leading zero if needed
  // Set time to "00:00" to comply with the "datetime-local" input format
  const time = 'T00:00';
  return `${year}-${month}-${day}${time}`;
}

export function getTodayTime() {
  const today = new Date();
  const year = today.getFullYear();
  const month = (`0${today.getMonth() + 1}`).slice(-2); // Add leading zero if needed
  const day = (`0${today.getDate()}`).slice(-2); // Add leading zero if needed
  const hours = String(today.getHours()).padStart(2, '0')
  const minutes = String(today.getMinutes()).padStart(2, '0')
  const time = `T${hours}:${minutes}`
  // const time = 'T05:00';
  return `${year}-${month}-${day}${time}`;
}

export function getInThreeWeeks() {
  const today = new Date();
  // Calculate 3 weeks in milliseconds
  const threeWeeksInMs = 3 * 7 * 24 * 60 * 60 * 1000;
  // Add 3 weeks to the current date
  const futureDate = new Date(today.getTime() + threeWeeksInMs);
  const year = futureDate.getFullYear();
  const month = (`0${futureDate.getMonth() + 1}`).slice(-2); // Add leading zero if needed
  const day = (`0${futureDate.getDate()}`).slice(-2); // Add leading zero if needed
  // Assuming you want to keep the time as "00:00" for consistency
  const time = 'T00:00';
  return `${year}-${month}-${day}${time}`;
}

