<template>
    <div class="flex flex-col items-center">
      <div v-if="permissions.includes(`olp_change_vision_${vision.id}`) && permissions.includes('product.add_vision')">
        <button @click="isModalOpen = true" class="actionButtonWhite font-bold">{{ vision.name }}</button>
        <Teleport to="#modal">
          <Transition name="modal">
            <div v-if="isModalOpen" class="modal-bg" @click.self="isModalOpen = false">
              <div class="modal">
                <Component_Modal_InfoButton :infoText="infoTextAddVision" />
                <button @click="isModalOpen = false" class="close-btn">x</button>
                <Modal_ChangeVision
                  v-if="permissions.includes(`olp_change_vision_${vision.id}`)"
                  v-bind:vision="vision"
                  v-on:visionChanged="handleVisionChanged"
                />
              </div>
            </div>
          </Transition>
        </Teleport>
      </div>
      <strong v-else class="overview-header m-2 mt-[10px]">
          {{ vision.name }}
      </strong>
    </div>
  
    <div v-if="filteredMissions.length > 0" class="flex flex-row flex-wrap gap-4">
      <div class="overview-background bg-vantablue-400" v-for="(mission, index) in filteredMissions" :key="mission.id">
        <div class="actually-centered">
            <!-- <button class="bg-black round-button-small">
                <div class="text-[rgb(0,175,240)] font-bold">
                  x{{ mission.weight_multiplier }}
                </div>
            </button> -->
            <Component_Overview_Mission
              :mission="mission"
              :moals="moals"
              :objectives="objectives"
              :keyresults="keyresults"
              :permissions="permissions"
              v-on:missionChanged="handleVisionChanged"
            />
        </div>  
      </div>
    </div>
  </template>


<script>
import axios from 'axios'
import { onMounted } from 'vue';
import { useMissions } from '@/composables/useObjects'; // Ensure the path is correct

import { mapState } from 'vuex'
import Component_Overview_Mission from '@/components/Overview/Component_Overview_Mission.vue'   
import Modal_ChangeVision from '@/components/Modals/Modal_ChangeVision.vue';
import Component_Modal_InfoButton from '@/components/Component_Modal_InfoButton.vue';
import { infoTextAddVision } from '@/utils/infotexts';

export default {
    name: 'Component_Overview_Vision',
    emits: ['visionChanged'],
    components:
    {
      Component_Overview_Mission,
      Modal_ChangeVision,
      Component_Modal_InfoButton
    },
    props: {
        vision: Object,
        missions: Array,
        moals: Array,
        objectives: Array,
        keyresults: Array,
        permissions: {
            type: Array,
            required: true
        }
    },
    watch: {
      missions(newValue, oldValue) {
        this.filterMissions();
      }
    },
    data() {
        return {
          filteredMissions: [],
          isModalOpen: false,
          infoTextAddVision: infoTextAddVision,

        }
    },
    mounted() {
      this.filterMissions();
    },
    methods: {
      async filterMissions() {
        this.filteredMissions = this.missions.filter(mission => mission.vision === this.vision.id);
      },
      handleVisionChanged() {
          this.isModalOpen = false;
          this.$emit('visionChanged');
          // setup(props);
          
      },
    },
}
</script>@/composables/useObjects

<style scoped>
.text-with-circle-button {
  position: relative;
  border: none;
  background-color: transparent; /* Remove default button background */
  color: white; /* Text color */
  padding: 20px; /* Adjust based on the size of the circle and text */
  cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
  outline: none; /* Remove default focus outline */
  text-align: center; /* Center the text */
  z-index: 2; /* Ensure the text appears above the circle */
}

.text-with-circle-button::before {
  content: '';
  position: absolute;
  top: 0; /* Aligns the top of the circle with the button's top */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Ensure exact centering */
  width: 100px; /* Diameter of the circle */
  height: 50px; /* Half the diameter to create a half-circle */
  background-color: black; /* Circle color */
  border-radius: 50px 50px 0 0; /* Fully rounded top, flat bottom */
  z-index: 1; /* Ensure the text appears above the circle */
}

.button-container {
  position: relative;
  height: 100%; /* Adjust based on your layout */
  width: 100%; /* Adjust based on your layout */
}
</style>